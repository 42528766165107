@import 'react-quill/dist/quill.snow.css';
@import 'quill-image-uploader/dist/quill.imageUploader.min.css';

.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0;
  border-color: #ced4da;
}

.ql-container.ql-snow {
  border-radius: 0 0px 6px 6px;
  border-color: #ced4da;
  min-height: 100px;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #b3b3b3;
}

.ql-toolbar .ql-fill {
  fill: #b3b3b3;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #b3b3b3;
}

.ql-tooltip.ql-editing {
  box-shadow: none;
  left: unset !important;
  right: 0px !important;
  transform: translateY(-30px);
  z-index: 999;
}
